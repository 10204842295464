import React from "react";
import Text from "common/src/components/Text";
import Alert from "common/src/components/Alert";

const Beer = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
        background: "#00000099",
        width: "100%",
        height: "100%",
        alignContent: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Alert isMaterial style={{ background: "white", maxWidth: 600 }}>
        <div
          style={{
            backgroundColor: "grey",
            borderRadius: 99,
            width: 150,
            height: 150,
            paddingTop: 20,
            marginBottom: 20,
          }}
        >
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="98px"
            height="100px"
            viewBox="0 0 98 100"
            enable-background="new 0 0 98 100"
          >
            <path
              fill="#FFFFFF"
              d="M85.715,26.547h-7.457c1.711-5.512,0.539-11.744-3.043-15.975c-3.587-4.236-9.158-6.766-14.896-6.766
    c-4.109,0-7.852,1.29-10.733,3.6c-3.045-4.6-9.469-7.03-15.613-7.03c-7.055,0-14.166,3.225-16.001,10.323
    c-1.147-0.196-2.492-0.347-3.91-0.347c-8.456,0-13.503,5.16-13.846,14.155c-0.185,4.844,1.047,8.677,3.662,11.394
    c1.359,1.413,3.049,2.442,4.988,3.094v48.546c0,6.663,5.421,12.083,12.083,12.083h43.364c6.662,0,12.084-5.42,12.084-12.083v-9.373
    c0,0,0,0.001,0.002,0.001c0.006,0,0.013-0.007,0.018-0.008l9.305,0.004c6.661,0,12.084-5.419,12.084-12.083V38.63
    C97.799,31.968,92.378,26.547,85.715,26.547z"
            />
            <path
              fill="none"
              d="M85.715,32.589H75.023v-0.178c-2.688,3-6.535,4.606-11.271,4.606c-4.216,0-8.304-1.251-10.778-2.189
    c-2.216,2.709-6.748,6.943-13.577,7.167c-0.236,0.008-0.467,0.012-0.696,0.012c-6.706,0-10.612-3.162-12.737-5.904
    c-2.943,2.091-7.034,3.49-11.06,3.669V87.54c0,3.321,2.719,6.041,6.042,6.041h43.366c3.322,0,6.041-2.72,6.041-6.041v-9.376
    c0-3.322,2.723-6.041,6.043-6.041h9.318c3.322,0,6.043-2.719,6.043-6.042v-27.45C91.758,35.307,89.037,32.589,85.715,32.589z"
            />
            <path
              fill="none"
              d="M75.042,32.605h10.69c1.653,0,3.156,0.674,4.25,1.759c-1.096-1.095-2.606-1.775-4.268-1.775H75.041
    L75.042,32.605L75.042,32.605z"
            />
            <path
              fill="none"
              d="M39.416,42.01c6.829-0.225,11.361-4.458,13.576-7.167c2.476,0.938,6.562,2.188,10.777,2.188
    c4.727,0,8.567-1.6,11.254-4.588v-0.032c-2.688,3-6.535,4.606-11.271,4.606c-4.216,0-8.303-1.251-10.778-2.189
    c-2.216,2.709-6.748,6.943-13.577,7.167c-0.236,0.008-0.467,0.012-0.696,0.012c-4.608,0-7.895-1.493-10.189-3.317
    c2.295,1.831,5.586,3.332,10.207,3.332C38.949,42.022,39.18,42.018,39.416,42.01z"
            />
            <path
              fill="none"
              d="M14.923,87.557V39.788c4.025-0.18,8.114-1.577,11.056-3.667c-0.004-0.006-0.01-0.012-0.014-0.018
    c-2.943,2.091-7.034,3.49-11.06,3.669V87.54c0,1.669,0.688,3.186,1.792,4.281C15.603,90.727,14.923,89.217,14.923,87.557z"
            />
            <polygon
              fill="#FF9C08"
              points="80.162,59.326 80.162,45.717 75.939,45.717 75.939,59.33 80.162,59.33 "
            />
            <polygon
              fill="none"
              points="75.939,59.33 80.162,59.33 80.162,59.326 80.162,45.717 75.939,45.717 "
            />
            <g>
              <polygon
                fill="#FF9C08"
                points="75.939,59.33 80.162,59.33 80.162,59.326 80.162,45.717 75.939,45.717     "
              />
              <path
                fill="#FF9C08"
                d="M89.982,34.364c-1.094-1.085-2.597-1.759-4.25-1.759h-10.69h-0.001v-0.016h-0.018v-0.146
        c-2.687,2.988-6.527,4.588-11.254,4.588c-4.215,0-8.302-1.25-10.777-2.188c-2.215,2.709-6.747,6.942-13.576,7.167
        c-0.236,0.008-0.467,0.012-0.696,0.012c-4.621,0-7.912-1.501-10.207-3.332c-1.038-0.825-1.872-1.717-2.534-2.569
        c-2.942,2.09-7.031,3.487-11.056,3.667v47.769c0,1.66,0.68,3.17,1.774,4.265c1.094,1.086,2.597,1.76,4.25,1.76h43.366
        c3.322,0,6.041-2.72,6.041-6.041v-9.376c0-3.322,2.723-6.041,6.043-6.041h9.318c3.322,0,6.043-2.719,6.043-6.042v-27.45
        C91.758,36.97,91.078,35.459,89.982,34.364z M85.6,59.297c0.01,0.526-0.062,2.496-1.494,3.98c-0.656,0.681-1.832,1.492-3.692,1.492
        h-7.19c-1.502,0-2.721-1.219-2.721-2.72V42.999c0-1.502,1.219-2.719,2.721-2.719h7.03c1.979,0,3.205,0.814,3.884,1.498
        c1.405,1.417,1.467,3.22,1.463,3.567V59.297z"
              />
            </g>

            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new"
              d="M28.018,81.221c0,1.975-1.6,3.573-3.574,3.573l0,0
    c-1.974,0-3.574-1.601-3.574-3.573l0,0c0-1.975,1.601-3.574,3.574-3.574l0,0C26.418,77.646,28.018,79.246,28.018,81.221
    L28.018,81.221z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="0 50;0 -50;"
                dur="2.8s"
                repeatCount="indefinite"
              />
            </path>
            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new    "
              d="M39.018,51.221c0,1.975-1.6,3.573-3.574,3.573l0,0
    c-1.974,0-3.574-1.601-3.574-3.573l0,0c0-1.975,1.601-3.575,3.574-3.575l0,0C37.418,47.646,39.018,49.246,39.018,51.221
    L39.018,51.221z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="0 50;0 -50;"
                dur="4s"
                repeatCount="indefinite"
              />
            </path>

            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new    "
              d="M65.018,81.221c0,1.975-1.6,3.573-3.574,3.573l0,0
    c-1.973,0-3.573-1.601-3.573-3.573l0,0c0-1.975,1.601-3.574,3.573-3.574l0,0C63.418,77.646,65.018,79.246,65.018,81.221
    L65.018,81.221z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="2 50;2 -50;"
                dur="2.2s"
                repeatCount="indefinite"
              />
            </path>

            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new    "
              d="M58.018,59.221c0,1.975-1.6,3.573-3.574,3.573l0,0
    c-1.973,0-3.573-1.601-3.573-3.573l0,0c0-1.975,1.601-3.574,3.573-3.574l0,0C56.418,55.646,58.018,57.246,58.018,59.221
    L58.018,59.221z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="0 50;0 -50;"
                dur="2.5s"
                repeatCount="indefinite"
              />
            </path>
            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new    "
              d="M23.165,61.221c0,0.949-0.771,1.719-1.721,1.719l0,0
    c-0.949,0-1.72-0.77-1.72-1.719l0,0c0-0.951,0.771-1.721,1.72-1.721l0,0C22.394,59.5,23.165,60.27,23.165,61.221L23.165,61.221z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="0 50;0 -50;"
                dur="3s"
                repeatCount="indefinite"
              />
            </path>
            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new    "
              d="M45.165,71.221c0,2.055-1.667,3.719-3.721,3.719l0,0
    c-2.053,0-3.719-1.664-3.719-3.719l0,0c0-2.056,1.667-3.72,3.719-3.72l0,0C43.498,67.501,45.165,69.165,45.165,71.221L45.165,71.221
    z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="0 50;0 -50;"
                dur="4s"
                repeatCount="indefinite"
              />
            </path>
            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new    "
              d="M43.877,86.221c0,1.344-1.09,2.432-2.434,2.432l0,0
    c-1.343,0-2.433-1.088-2.433-2.432l0,0c0-1.345,1.09-2.434,2.433-2.434l0,0C42.788,83.787,43.877,84.876,43.877,86.221
    L43.877,86.221z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="0 50;0 -50;"
                dur="3s"
                repeatCount="indefinite"
              />
            </path>
            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new    "
              d="M63.999,42.22c0,1.411-1.146,2.554-2.556,2.554l0,0
    c-1.408,0-2.553-1.143-2.553-2.554l0,0c0-1.411,1.145-2.554,2.553-2.554l0,0C62.854,39.666,63.999,40.809,63.999,42.22L63.999,42.22
    z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="0 50;0 -50;"
                dur="3s"
                repeatCount="indefinite"
              />
            </path>
            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new    "
              d="M23.109,46.601c0,0.709-0.575,1.283-1.285,1.283l0,0
    c-0.708,0-1.283-0.573-1.283-1.283l0,0c0-0.709,0.575-1.284,1.283-1.284l0,0C22.534,45.317,23.109,45.891,23.109,46.601
    L23.109,46.601z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="0 50;0 -50;"
                dur="2s"
                repeatCount="indefinite"
              />
            </path>
            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new    "
              d="M53.075,49.444c0,0.709-0.574,1.283-1.284,1.283l0,0
    c-0.708,0-1.283-0.573-1.283-1.283l0,0c0-0.709,0.575-1.283,1.283-1.283l0,0C52.5,48.161,53.075,48.734,53.075,49.444L53.075,49.444
    z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="0 50;0 -50;"
                dur="2.5s"
                repeatCount="indefinite"
              />
            </path>
            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new    "
              d="M53.497,74.723c0,0.711-0.575,1.283-1.284,1.283l0,0
    c-0.708,0-1.283-0.572-1.283-1.283l0,0c0-0.709,0.575-1.281,1.283-1.281l0,0C52.921,73.439,53.497,74.014,53.497,74.723
    L53.497,74.723z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="0 50;0 -50;"
                dur="2.2s"
                repeatCount="indefinite"
              />
            </path>
            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new    "
              d="M26.061,70.771c0,0.709-0.575,1.283-1.284,1.283l0,0
    c-0.708,0-1.283-0.574-1.283-1.283l0,0c0-0.709,0.575-1.283,1.283-1.283l0,0C25.486,69.486,26.061,70.062,26.061,70.771
    L26.061,70.771z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="0 50;0 -50;"
                dur="2s"
                repeatCount="indefinite"
              />
            </path>
            <path
              opacity="0.3"
              fill="#FFFFFF"
              enable-background="new    "
              d="M65.877,68.221c0,1.344-1.09,2.432-2.434,2.432l0,0
    c-1.344,0-2.434-1.088-2.434-2.432l0,0c0-1.345,1.09-2.434,2.434-2.434l0,0C64.788,65.787,65.877,66.876,65.877,68.221
    L65.877,68.221z"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values="-10 50;-10 -50;"
                dur="5s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
        <Text
          content={
            "Congratulations! You solved all the puzzles. Thank you so much for helping me find out the rest of this information so I can get everything set up for our event. I hope you join us for the night!"
          }
        />
        <Text
          content={
            "If you’d like to participate in beer tasting, pick out your favorite beers and take a look at your tasting notes! "
          }
        />
      </Alert>
    </div>
  );
};

export default Beer;
