import React, { Fragment, useState, useEffect } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import { agencyTheme } from "common/src/theme/agency";
import { ResetCSS } from "common/src/assets/css/style";
import "@redq/reuse-modal/es/index.css";
import {
  GlobalStyle,
  AgencyWrapper,
} from "../../../containers/Agency/agency.style";
import Navbar from "../../../containers/Agency/Navbar";
import GamesSection from "../../../containers/Games";
import Footer from "../../../containers/Agency/Footer";
import { DrawerProvider } from "common/src/contexts/DrawerContext";
import SEO from "../../../components/seo";
import Card from "common/src/components/Card";
import Text from "common/src/components/Text";
import Container from "common/src/components/UI/Container";
import FeatureSectionWrapper from "../../../containers/Games/gamesSection.style";
import Box from "common/src/components/Box";
import Heading from "common/src/components/Heading";
import Input from "common/src/components/Input";
import Button from "common/src/components/Button";
import Beer from "../../../containers/Games/Beer";
import { navigate } from "gatsby";
import letterUrl from "../../../images/iansLetter.png";
import DatePicker from "common/src/components/DatePicker";

const hints = [
  [
    "Think about why Ian changed the time of the event. What was his reasoning?",
    "Charlie and Ian talk in 24 hours.",
    "What numbers in general or four digit number appear most often? There are two options of numbers you can enter for a correct answer (both mean the same thing).",
  ],
  [
    "Check the Facebook group to learn more about what beers Ian likes.",
    "The beer is ready on the same day of the week that it is brewed and it will finish on the same day as the event.",
    "Mystery Brewing is not open every day of the week.",
  ],
  [
    "Look at the event budget.",
    "Look at the newspaper for more information about the speaker’s brewery.",
    "The beer for the night was specially brewed in his honor.",
  ],
  [
    "You first need to know how many people are attending the beer tasting (it doesn’t explicitly say anywhere).",
    "There’s enough beer for tonight’s tasting ONLY (and yes, everyone will be drinking).",
    "Remember how many guests there are and make sure you discard any caterers who cannot cater that many attendees.",
    "Look in the newspaper for a clue to narrow down your options on caterers.",
    "You need to do some math to figure out which caterer fits in the budget and can accomodate your guests.",
  ],
];

const TheLostBrewer = () => {
  const [error, setError] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [code, setCode] = useState("");
  const [hintState, setHintState] = useState([-1, -1, -1, -1]);

  const [time, setTime] = useState("");
  const isTimeValid = () =>
    [
      "7",
      "7 pm",
      "7pm",
      "7:00",
      "7:00pm",
      "7:00 pm",
      "19 00",
      "1900",
      "19:00",
      "19.00",
    ].includes(time.trim().toLowerCase());

  const [guest, setGuest] = useState("");
  const isGuestValid = () =>
    ["martin short"].includes(guest.trim().toLowerCase());

  const [catering, setCatering] = useState("");
  const isCateringValid = () =>
    ["magnificent tastes catering", "magnificent tastes"].includes(
      catering.trim().toLowerCase()
    );

  const [favourite, setFavourite] = useState("");
  const isFavouriteValid = () =>
    ["short and stout"].includes(favourite.trim().toLowerCase());

  useEffect(() => {
    setError(null);
  }, [time, favourite, catering, guest]);

  const [isCodeValid, setIsCodeValid] = useState(false);
  const isMobile = typeof window !== "undefined" && window.innerWidth < 960;

  const newLocal = () => {
    setError(null);

    if (correctAnswers === 0) {
      if (isTimeValid()) {
        setCorrectAnswers(1);
      } else {
        setError(0);
      }
    }

    if (correctAnswers === 1) {
      if (isFavouriteValid()) {
        setCorrectAnswers(2);
      } else {
        setError(1);
      }
    }

    if (correctAnswers === 2) {
      if (isGuestValid()) {
        setCorrectAnswers(3);
      } else {
        setError(2);
      }
    }

    if (correctAnswers === 3) {
      if (isCateringValid()) {
        setCorrectAnswers(4);
      } else {
        setError(3);
      }
    }
  };

  return (
    <div style={{ textAlign: "center", textAlign: "-webkit-center" }}>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 8 }}
      >
        <Button
          title=""
          style={{
            marginRight: 8,
            backgroundColor: "transparent",
            color: "black",
          }}
          icon={<i className="flaticon-left-arrow" />}
          onClick={() => navigate("/games")}
        />
        <Text
          content="MYSTERY BREWING"
          as="h1"
          style={{ marginTop: "auto", marginBottom: "auto" }}
          fontWeight={300}
        />
      </div>
      {isCodeValid && (
        <div
          style={{
            flexDirection: "row",
            display: isMobile ? "block" : "flex",
            flex: 1,
          }}
        >
          <Card
            className="card"
            style={{
              flex: 1,
              marginRight: isMobile ? 0 : 16,
              marginBottom: isMobile ? 40 : 0,
              padding: 16,
            }}
          >
            <img src={letterUrl} style={{ maxWidth: "100%" }} alt={""} />
          </Card>
          <div style={{ flex: 1 }}>
            <Input
              value={time}
              onChange={setTime}
              disabled={correctAnswers > 0}
              style={{ marginBottom: 24, color: error === 0 && "red" }}
              isMaterial
              label="What time is the event?"
            />
            {hintState[0] >= 0 && <Text content={hints[0][0]} />}
            {hintState[0] >= 1 && <Text content={hints[0][1]} />}
            {hintState[0] >= 2 && <Text content={hints[0][2]} />}
            {hintState[0] < 2 && (
              <Button
                style={{ width: "100%", marginBottom: 24 }}
                title={hintState[0] >= 0 ? "See next hint" : "See a hint"}
                onClick={() => {
                  const tempHintState = [...hintState];
                  tempHintState[0] = tempHintState[0] + 1;
                  setHintState(tempHintState);
                }}
              />
            )}
            {correctAnswers > 0 && (
              <Input
                value={favourite}
                onChange={setFavourite}
                disabled={correctAnswers > 1}
                style={{ marginBottom: 24, color: error === 1 && "red" }}
                isMaterial
                label="Name of his favourite beer?"
              />
            )}
            {hintState[1] >= 0 && <Text content={hints[1][0]} />}
            {hintState[1] >= 1 && <Text content={hints[1][1]} />}
            {hintState[1] >= 2 && <Text content={hints[1][2]} />}
            {correctAnswers > 0 && hintState[1] < 2 && (
              <Button
                disabled={hintState[1] >= 2}
                style={{ width: "100%", marginBottom: 24 }}
                title={hintState[1] >= 0 ? "See next hint" : "See a hint"}
                onClick={() => {
                  const tempHintState = [...hintState];
                  tempHintState[1] = tempHintState[1] + 1;
                  setHintState(tempHintState);
                }}
              />
            )}
            {correctAnswers > 1 && (
              <Input
                value={guest}
                onChange={setGuest}
                disabled={correctAnswers > 2}
                style={{ marginBottom: 24, color: error === 2 && "red" }}
                isMaterial
                label="Name of the guest Speaker?"
              />
            )}
            {hintState[2] >= 0 && <Text content={hints[2][0]} />}
            {hintState[2] >= 1 && <Text content={hints[2][1]} />}
            {hintState[2] >= 2 && <Text content={hints[2][2]} />}
            {correctAnswers > 1 && hintState[2] < 2 && (
              <Button
                disabled={hintState[2] >= 2}
                style={{ width: "100%", marginBottom: 24 }}
                title={hintState[2] >= 0 ? "See next hint" : "See a hint"}
                onClick={() => {
                  const tempHintState = [...hintState];
                  tempHintState[2] = tempHintState[2] + 1;
                  setHintState(tempHintState);
                }}
              />
            )}
            {correctAnswers > 2 && (
              <Input
                value={catering}
                onChange={setCatering}
                disabled={correctAnswers > 3}
                style={{ marginBottom: 24, color: error === 3 && "red" }}
                isMaterial
                label="Name of the Catering company?"
              />
            )}
            {hintState[3] >= 0 && <Text content={hints[3][0]} />}
            {hintState[3] >= 1 && <Text content={hints[3][1]} />}
            {hintState[3] >= 2 && <Text content={hints[3][2]} />}
            {hintState[3] >= 3 && <Text content={hints[3][3]} />}
            {hintState[3] >= 4 && <Text content={hints[3][4]} />}
            {correctAnswers > 2 && hintState[3] < 4 && (
              <Button
                style={{ width: "100%", marginBottom: 24 }}
                title={hintState[3] >= 0 ? "See next hint" : "See a hint"}
                onClick={() => {
                  const tempHintState = [...hintState];
                  tempHintState[3] = tempHintState[3] + 1;
                  setHintState(tempHintState);
                }}
              />
            )}
            {correctAnswers > 3 && <Beer />}
            {correctAnswers < 4 && (
              <Button
                style={{ width: "100%" }}
                title="CHECK ANSWER"
                onClick={newLocal}
              />
            )}
          </div>
        </div>
      )}
      {!isCodeValid && (
        <Card
          className="card"
          style={{
            flex: 1,
            marginRight: isMobile ? 0 : 16,
            marginBottom: isMobile ? 40 : 0,
            padding: 16,
          }}
        >
          <Input
            style={{ marginTop: 24, marginBottom: 24 }}
            isMaterial
            label="Enter your unique code for this game"
            value={code}
            onChange={setCode}
          />
          <Button
            style={{ width: "100%" }}
            title="VERIFY"
            disabled={!code || code === ""}
            onClick={() =>
              setIsCodeValid("8359a87b-5184-4d27-b31e-892d2aa99f86" === code)
            }
          />
        </Card>
      )}
    </div>
  );
};

export default () => {
  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title="Games by ROMA" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          <FeatureSectionWrapper id="featureSection">
            <Container>
              <Box>
                <TheLostBrewer />
              </Box>
            </Container>
          </FeatureSectionWrapper>
        </AgencyWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
